import _ from 'lodash';
import { returnCourseApiName } from './services/getcourse';
export const getDomain = () => {
  const domain = `${window.location.protocol}//${window.location.host}`;
  return domain;
};

const DOMAIN = getDomain() || 'http://localhost:3000';

export const ALMABETTER_HOME_LINKS = {
  ROOT: 'https://www.almabetter.com',
  get TERM_AND_CONDITIONS() {
    return `${this.ROOT}/terms-of-use`;
  },
  get PRIVACY() {
    return `${this.ROOT}/privacy-policy`;
  },
  get ABOUT_US() {
    return `${this.ROOT}/about-us`;
  },
  get COMMUNITY() {
    return `${this.ROOT}/community`;
  },
};

export const THINKIFIC_ALMABETTER_LINKS = {
  ROOT: 'https://learn.almabetter.com',
  get DATA_SCIENCE_PRO_LIVE_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-live-onboarding/multimedia/27676716-almax-pre-registration-form`;
  },
  get DATA_SCIENCE_PRO_FLEX_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-flex-onboarding/multimedia/27488097-almax-pre-registration-form`;
  },
  get WEB3_PRO_LIVE_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/web-development-pro-program-onboarding/multimedia/33533152-almax-pre-registration-form`;
  },
  get ENROLLMENTS_PAGE() {
    return `${this.ROOT}/enrollments`;
  },
  get SSO_LOGOUT_URL() {
    return `${this.ROOT}/users/sign_out`;
  },
};

export const IMAGE_FILE_SIZE_MAX = 1449616;

export const THINKIFIC_CONSTANTS = {
  SSO_LOGIN: {
    URL: 'https://almabetter.thinkific.com/api/sso/v2/sso/jwt',
    ERROR_URL: `${DOMAIN}/thinkific_login_error`,
  },
};

export const LOCAL_STORAGE_KEYS = {
  REDIRECT_TO: 'REDIRECT_TO',
  LOGIN_PROCESS_STAGE: 'LOGIN_PROCESS_STAGE',
  LOGOUT_PROCESS_STAGE: 'LOGOUT_PROCESS_STAGE',
  COURSE_INTERESTS: 'COURSE_INTERESTS',
  REFERRAL_CODE: 'REFERRAL_CODE',
  REFERRAL_GOAL: 'REFERRAL_GOAL',
  REFERRAL_PLAN: 'REFERRAL_PLAN',
  LINKEDIN_SHARE: 'LINKEDIN_SHARE',
  REFERRAL_SOURCE: 'REFERRAL_SOURCE',
  JOB_DASHBOARD_VIEWED: 'JOB_DASHBOARD_VIEWED',
  GLOBAL_SETTINGS: 'GLOBAL_SETTINGS',
  CAMPAIGN_PARAMS: 'CAMPAIGN_PARAMS',
};

export const LOGIN_LOGOUT_PROCESS_STAGE_VALUES = {
  COGNITO_AUTH_SUCCESSFUL: 'COGNITO_AUTH_SUCCESSFUL',
  LOGOUT_STARTED: 'LOGOUT_STARTED',
};

export const ASSIGNMENT_SUBMISSION_INSTRUCTION_VIDEO_URL =
  'https://ab-public-bucket-dev.s3.ap-south-1.amazonaws.com/submissionPart1.mp4';
export const CAPSTONE_PROJECT_SUBMISSION_INSTRUCTION_VIDEO_URL =
  'https://ab-public-bucket-dev.s3.ap-south-1.amazonaws.com/capstoneProjectVideo.mp4';

export const COURSES_OFFERED = ['data-science', 'wev-dev'];

export const RAZORPAY = {
  SCRIPT_SRC: 'https://checkout.razorpay.com/v1/checkout.js',
  KEY_ID: process.env.REACT_APP_RAZORPAY_KEY_ID || 'rzp_test_gVz50FqbpOF707',
  CURRENCY: 'INR',
};

export const ALMABETTER_LOGO_URL =
  'https://ab-public-bucket-prod.s3.ap-south-1.amazonaws.com/razorpay_icon.png';

export const PLAN_TYPE = {
  PRO_FLEX: 'pro_flex',
  PRO_LIVE: 'pro_live',
  PRO_LEGACY: 'pro_legacy',
  PRO_UPGRADE: 'pro_upgrade',
  NEW_USER: 'new_user',
  PREMIUM: 'premium',
  CORPORATE: 'corporate_training',
  MASTER: 'masters',
};

export const PLAN_TYPE_NAMES_MAPPING = {
  [PLAN_TYPE.PRO_FLEX]: 'Pro Flex',
  [PLAN_TYPE.PRO_LIVE]: 'Pro Live',
  [PLAN_TYPE.PRO_LEGACY]: 'Pro User',
  [PLAN_TYPE.NEW_USER]: 'New User',
  [PLAN_TYPE.PREMIUM]: 'Premium',
  [PLAN_TYPE.MASTER]: 'Masters',
};

export const COURSE_TYPE = {
  DATA_SCIENCE: 'data-science',
  WEB_DEV: 'web-dev',
  DATA_ENGINEERING: 'data-engineering',
  DS_BOOTCAMP: 'ds-bootcamp',
  MASTERS_DS_AS: 'masters-ds',
  MASTERS_CS: 'masters-cs',
  UG_DIPLOMA_CS: 'ug-diploma-cs',
  WEB3: 'web3',
};

export const COURSE_TYPE_NAMES_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Certification in Full Stack Data Science and AI',
  [COURSE_TYPE.WEB_DEV]: 'Certification in Full Stack Web Development',
  [COURSE_TYPE.DS_BOOTCAMP]: 'Data Science Ninja Bootcamp',
  [COURSE_TYPE.DATA_ENGINEERING]: 'Full Stack Data Engineering',
  [COURSE_TYPE.MASTERS_DS_AS]: 'Masters in CS : Data Science and Artificial Intelligence',
  [COURSE_TYPE.MASTERS_CS]: 'Masters in Computer Science : Software Engineering',
  [COURSE_TYPE.UG_DIPLOMA_CS]: 'UG Diploma in Computer Science',
};

export const BOOKING_STAGE = {
  CREATED: 'created',
  TIME_SLOTS_ADDED: 'timeSlotsAdded',
  COACH_ASSIGNED: 'coachAssigned',
  SESSION_CONDUCTED: 'sessionConducted',
  FEEDBACK_SUBMITTED: 'feedbackSubmitted',
  ADMIN_ON_HOLD: 'adminOnHold',
  ADMIN_APPROVED: 'adminApproved',
  CANCELLED: 'cancelled',
};

export const BOOKING_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  DEFAULTED: 'defaulted',
};

export const USER_CREDITS_DEDUCTION_AMOUNT = 15;
export const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://strapiv2.almagrow.com/api/';

export const COMMUNITY = 'https://community.almabetter.com/';
export const DATE_FORMAT_1 = 'Do MMM YYYY, hh:mm A';
export const DATE_ONLY_FROMAT_1 = 'Do MMM YYYY';
export const DATE_ONLY_FROMAT_2 = 'dddd, DD MMM YYYY';

export const MENTORSHIP_RESOURCE_LINK =
  'https://almabetter.notion.site/Mentorship-65a81629919b4ee1b26efaa393d6851c';

export const FRESHDESK_SUPPORT_URL =
  'https://almabetter.myfreshworks.com/login/auth/1662641230821?client_id=451980303908285122&redirect_uri=https%3A%2F%2Falmabetter-support.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback%3Fhd%3Dsupport.almabetter.com';

export const PAYMENT_ONBOARDING_FORM_STAGE = {
  BASIC_INFO: 'BASIC_INFO',
  EDUCATION: 'EDUCATION',
  WORK_EXPERIENCE: 'WORK_EXPERIENCE',
  SOCIAL: 'SOCIAL',
  SKILLS: 'SKILLS',
  GOAL: 'GOAL',
};

export const UG_PAYMENT_ONBOARDING_FORM_STAGE = {
  BASIC_INFO: 'BASIC_INFO',
  TWELTH_EDUCATION: '12_EDUCATION',
  GRADUATION_EDUCATION: 'GRADUATION_EDUCATION',
  GOAL: 'GOAL',
};

const COURSE_PATHS = {
  FULL_STACK_DATA_SCIENCE: 'full-stack-data-science',
  FULL_STACK_WEB_DEVELOPMENT_WITH_WEB3: 'full-stack-web-development-with-web3',
  DS_BOOTCAMP: 'ds-bootcamp',
  ALMAX_THE_LAST_MILE: 'almax-the-last-mile-new',
  DATA_ENGINEERING: 'full-stack-data-engineering',
  MASTERS_DS_AS: 'masters-ds',
  MASTERS_CS: 'masters-cs',
  UG_DIPLOMA_CS: 'ug-diploma-cs',
};
export const COURSE_TYPE_COURSE_PATH_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: COURSE_PATHS.FULL_STACK_DATA_SCIENCE,
  [COURSE_TYPE.WEB_DEV]: COURSE_PATHS.FULL_STACK_WEB_DEVELOPMENT_WITH_WEB3,
  [COURSE_TYPE.DS_BOOTCAMP]: COURSE_PATHS.DS_BOOTCAMP,
  [COURSE_TYPE.DATA_ENGINEERING]: COURSE_PATHS.DATA_ENGINEERING,
  [COURSE_TYPE.MASTERS_DS_AS]: COURSE_PATHS.MASTERS_DS_AS,
  [COURSE_TYPE.MASTERS_CS]: COURSE_PATHS.MASTERS_CS,
  [COURSE_TYPE.UG_DIPLOMA_CS]: COURSE_PATHS.UG_DIPLOMA_CS,
};

export const COURSE_PATH_COURSE_TYPE_MAPPING = _.invert(COURSE_TYPE_COURSE_PATH_MAPPING);
export const getCriteriaForGivenCoursePath = (coursePath) => {
  if (COURSE_PATH_COURSE_TYPE_MAPPING[coursePath]) {
    const courseType = COURSE_PATH_COURSE_TYPE_MAPPING[coursePath];
    return { courseType };
  } else if (coursePath === COURSE_PATHS.ALMAX_THE_LAST_MILE) {
    return { courseType: COURSE_TYPE.DATA_SCIENCE, almax: true };
  }
  return {};
};
export const WEB_SOCKETS_ENDPOINT =
  process.env.REACT_APP_WEB_SOCKETS_ENDPOINT ||
  'wss://7nq4wvs58h.execute-api.ap-south-1.amazonaws.com/dev';

export const DISCORD_AUTH_CLIENT_ID =
  process.env.REACT_APP_DISCORD_AUTH_CLIENT_ID || '1098890425391132684';

export const COMMUNITY_EVENT_TYPES = {
  PAYMENT: 'payment',
  PLACEMENT: 'placement',
  ROUND_ASSIGNED: 'round-assigned',
  COURSE_COMPLETION: 'course-completion',
  PROJECT_COMPLETED: 'project-completed',
  ASSESSMENT_COMPLETED: 'assessment-completed',
  MODULE_COMPLETED: 'module-completed',
};

export const COURSE_SELECTOR = {
  certification: [
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.DATA_SCIENCE],
      value: COURSE_TYPE.DATA_SCIENCE,
      planType: PLAN_TYPE.PRO_LIVE,
      courseType: COURSE_TYPE.DATA_SCIENCE,
      duration: '6 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: false,
        iitGuwahati: true,
      },
      for: 'College students, graduates & working professionals.',
      outcome: '5-25 LPA Job + Certification',
    },
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.WEB_DEV],
      value: returnCourseApiName(COURSE_TYPE.WEB_DEV),
      planType: PLAN_TYPE.PRO_LIVE,
      courseType: COURSE_TYPE.WEB_DEV,
      duration: '6 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: false,
        iitGuwahati: true,
      },
      for: 'College students, graduates & working professionals.',
      outcome: '5-25 LPA Job + Certification',
    },
  ],
  masters: [
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.MASTERS_DS_AS],
      value: COURSE_TYPE.DATA_SCIENCE,
      planType: PLAN_TYPE.MASTER,
      courseType: COURSE_TYPE.MASTERS_DS_AS,
      duration: '12 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: true,
        iitGuwahati: true,
      },
      for: 'Graduates & Working Professionals',
      outcome: `5-40 LPA Job + Master's Degree`,
    },
    {
      name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.MASTERS_CS],
      value: returnCourseApiName(COURSE_TYPE.WEB_DEV),
      planType: PLAN_TYPE.MASTER,
      courseType: COURSE_TYPE.MASTERS_CS,
      duration: '12 months',
      type: 'Part-time',
      classType: 'Online live classes',
      accredited: {
        woolf: true,
        iitGuwahati: true,
      },
      for: 'Graduates & Working Professionals',
      outcome: `5-40 LPA Job + Master's Degree`,
    },
  ],
  // ugCourses: [
  //   {
  //     name: COURSE_TYPE_NAMES_MAPPING[COURSE_TYPE.UG_DIPLOMA_CS],
  //     value: COURSE_TYPE.UG_DIPLOMA_CS,
  //     planType: PLAN_TYPE.PRO_LIVE,
  //     courseType: COURSE_TYPE.UG_DIPLOMA_CS,
  //     duration: '12 months',
  //     type: 'Part-time',
  //     classType: 'Online live classes',
  //     accredited: {
  //       woolf: true,
  //       iitGuwahati: true,
  //     },
  //     for: '1st, 2nd & 3rd Year College Students',
  //     outcome: `Internship + Diploma + 5+LPA Job`,
  //   },
  // ],
};

export const ENROLLED_IN = {
  CERTIFICATION: 'certification',
  MASTERS: 'masters',
  UG_DIPLOMA: 'ugCourses',
};

export const COURSE_INTEREST_COURSE_TYPE = {
  dataScienceInterest: COURSE_TYPE.DATA_SCIENCE,
  softDevInterest: COURSE_TYPE.WEB_DEV,
  mastersDSInterest: COURSE_TYPE.MASTERS_DS_AS,
  mastersCSInterest: COURSE_TYPE.MASTERS_CS,
  ugdiplomaCSInterest: COURSE_TYPE.WEB_DEV,
};

export const getGoalAndPlanBasedOnInterest = {
  [COURSE_TYPE.DATA_SCIENCE]: {
    goalType: COURSE_TYPE.DATA_SCIENCE,
    planType: PLAN_TYPE.PRO_LIVE,
  },
  [COURSE_TYPE.WEB_DEV]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.PRO_LIVE,
  },
  [COURSE_TYPE.MASTERS_DS_AS]: {
    goalType: COURSE_TYPE.DATA_SCIENCE,
    planType: PLAN_TYPE.MASTER,
  },
  [COURSE_TYPE.MASTERS_CS]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.MASTER,
  },
  [COURSE_TYPE.UG_DIPLOMA_CS]: {
    goalType: returnCourseApiName(COURSE_TYPE.WEB_DEV),
    planType: PLAN_TYPE.PRO_LIVE,
  },
};

export const LESSON_ACTIVITIES_IDS_FOR_REFINER = {
  Concept: 'e4645f10-41ba-11ee-9727-81770d4ea292',
  'Concept-Colab': 'e4645f10-41ba-11ee-9727-81770d4ea292',
  'Concept-Notion': 'e4645f10-41ba-11ee-9727-81770d4ea292',
  Video: '8aa794c0-4268-11ee-a2af-f9fae2984f65',
  Quiz: 'a89d84f0-426e-11ee-a2ca-9feca5aa8883',
  Code: '0a405710-4272-11ee-8ad9-99e125048ea3',
  Assignment: 'e4eca580-4272-11ee-aa24-fd4a05278182',
};

export const SUB_PLAN_TYPES = {
  PREMIUM_AB: 'premium_ab',
  ICONIC_IITG: 'iconic_iitg',
  ICONIC_WOOLF: 'iconic_woolf',
  GLOBAL_IITG_WOOLF: 'global_iitg_woolf',
};

export const SUB_PLAN_TYPES_MAPPING = {
  [SUB_PLAN_TYPES.PREMIUM_AB]: 'Premium',
  [SUB_PLAN_TYPES.ICONIC_IITG]: 'Iconic',
  [SUB_PLAN_TYPES.ICONIC_WOOLF]: 'Iconic',
  [SUB_PLAN_TYPES.GLOBAL_IITG_WOOLF]: 'Global',
};

export const EMI_PLANS_FOR_SUB_PLAN_TYPES = {
  [SUB_PLAN_TYPES.ICONIC_IITG]: '*Pay in easy EMIs starting at ₹ 3,333 per month',
  [SUB_PLAN_TYPES.ICONIC_WOOLF]: '*Pay in easy EMIs starting at ₹ 3,333 per month',
  [SUB_PLAN_TYPES.GLOBAL_IITG_WOOLF]: '*Pay in easy EMIs starting at ₹ 5,555 per month',
};

export const COURSE_TYPE_COURSE_NAME_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Data Science',
  [COURSE_TYPE.WEB3]: 'Web Development',
  [COURSE_TYPE.UG_DIPLOMA_CS]: 'UG Diploma CS',
};

export const STUDENTS_ID = [
  '000febd2-7c05-4d53-87a9-936ed368d4ce',
  '005cd98c-27ff-49c7-92c7-7100d429ce18',
  '006d5598-0396-44e8-88d9-885169061e4b',
  '0070d94e-1612-404e-b5e2-2f4450f5abab',
  '029e5f77-20ed-4738-a078-48a801db8c20',
  '044896dc-4ba9-48c9-b9c5-891f552b728b',
  '048bdefc-45e7-4c12-a706-bd706c8b66f9',
  '0496958e-7a59-493b-823d-35051f70b3e4',
  '0575fa26-01fc-4db8-8be6-4ed563fa3522',
  '05a028fb-940e-4b77-bfd4-ba2ddf1e3504',
  '05bd1bc4-2346-46d1-a81d-3131fd7d56e5',
  '07be0e81-f163-4bbd-a1a7-dda76e14fa10',
  '0807c68f-cd8f-43ce-9770-7eabafab77a2',
  '0953e186-a952-4751-a69b-c3641f69d230',
  '0ad3949f-590d-49d9-b2b6-49749ceb6106',
  '0b6bff0d-a44a-40fd-9ac2-1cb08961d806',
  '0bf96a5a-a77f-4350-9123-f2dca623267f',
  '0c81a8a3-fd69-4ea4-af46-052f49842912',
  '0cb90192-b12f-404d-9034-fe17d552d34d',
  '0fa0ff94-8b76-4fa7-9525-1d1589d5f1cf',
  '109b1ef4-6dcc-4d72-9c93-8e88e6972b45',
  '13e45ff2-32ed-4ae4-a3ff-339c4929e55b',
  '1469347b-a2f5-4e3b-b60e-6f465ced19d8',
  '1651594b-e136-431d-a7c5-37b42347e21b',
  '16e5bb23-e5c8-40ed-b0aa-4d9dc4aea966',
  '1a2e5e8b-9852-46f5-aa2c-3b6256333405',
  '1af4c5c9-b061-4c55-b0c1-da295a1a396a',
  '1c67214a-c416-4f70-8b54-b6de7ef08c81',
  '1c744a33-8f51-455c-a44a-f305fd252098',
  '1cbc97c4-f697-4b6d-8bd0-aa155e0b77ec',
  '1d37badb-fc33-4176-8194-d6854fb92f21',
  '1d3a9cb7-25d7-4a58-bf00-b8e6f4fc6d7f',
  '1d6d027f-82e4-4641-9c01-00f7648f127a',
  '1df5a58f-6d70-4cc0-b447-04f7d3ad4284',
  '1e17212c-e38d-4d4a-b4b9-3b2ed667e9ba',
  '1e731435-c242-4bd4-974f-3e6863d21235',
  '1eb5726f-7803-4fbf-9751-5de248267344',
  '1fd6b8a3-baef-4170-9f1c-5acb4457f67c',
  '2011f3dd-dc78-4059-b65a-746bb66a49af',
  '20838574-5036-4117-8ffe-0cfa7b78bb34',
  '20a2cdb2-2da7-48a7-87ed-716e0b5b722d',
  '21e85fce-df1f-4ade-8fbf-b24eef794758',
  '222e4351-cab4-488d-8c1c-e0fddfb172fe',
  '22df76bb-9e84-4474-aac9-d9e2dd65153f',
  '2306a358-a4d0-4225-8b95-350c03399595',
  '23bf1ef5-c6de-4dd1-90d7-6656e9d590c0',
  '23dea2b9-fb69-486d-85b4-84a183e352a0',
  '24cb722c-688a-4446-a9ab-eac45c2ade12',
  '24cbae7e-fa86-4686-b5b5-2b9c11c7c25b',
  '24dad4c5-db25-411e-ac06-704cbae1047b',
  '25615ca8-32f7-4741-9e91-b138e8aeb393',
  '25f375be-9adc-47a7-a3cf-d6376eafb05f',
  '26cce5f5-2f0b-4ca9-b4fd-f26fcde27109',
  '29693fda-4250-4529-8778-818026539f02',
  '2a648530-1c8c-43aa-9337-7a3b4cb55b49',
  '2ad275c6-e057-4d59-90d7-6bc34bd26ff7',
  '2b3932c5-a804-4ace-9e6d-7137c7953df2',
  '2b8c3017-e377-46f0-8e1d-ab116f86bebe',
  '2bd666d6-5664-4f48-a9e6-13e9e1c5c4d5',
  '2c34f354-123c-4fc1-a6e9-52b5101516e0',
  '2c529d68-1465-4f8e-b350-2733c9f6bf6d',
  '2d184b80-37e0-40e7-ad47-521463a18c7e',
  '2d71870f-deb8-4783-a621-15f3b9d70a2a',
  '2eb9b8ae-7fc2-4481-a619-5d4b2ac77081',
  '2fe9538c-e2eb-4420-83a2-d9aa4776c0df',
  '2fef734e-e79a-4abb-9a9a-b4792434cb75',
  '30e97b7e-c8e5-43e9-9ab7-9581f348e794',
  '318dbad3-f125-4c12-be1f-ac00e6648935',
  '321e2def-26ad-4a9e-8e17-f0f2bc775969',
  '3244bd87-e37a-41ff-a786-0391c42ccf52',
  '32699601-b7fb-4f50-9009-9557c8ad80f1',
  '340086c2-ba2a-425a-97a5-d754d0abe8e5',
  '35848275-81f7-4ac2-a23f-e99e4dc40347',
  '35d525ad-5e6c-4ee2-b7de-b425e97b03d8',
  '36b6e3c1-cf0b-4752-b79f-b85223df4073',
  '3875ac14-407c-4333-8676-9cfab1c3289a',
  '38bbfae4-d84e-41f0-b73d-e30695ce56d3',
  '38e265c6-1cec-4eed-a40c-2ed71a4b73f1',
  '397f9167-8ea3-4138-8d08-0911f66d7179',
  '39dd0ad5-cc74-4c39-b3cd-7f67a917ddb1',
  '3b5871a8-adf9-47d0-9d0a-01d80c065832',
  '3c8e1dd7-4ce0-4f60-a7c0-9b45f5284bd7',
  '3ca28ead-65cf-46a9-b0c6-c3caee098885',
  '3ceffc5d-aec9-4d9e-9321-f3e14d1aa0ab',
  '3de0f352-2337-4e27-8ae2-ea2294b12997',
  '3e9d9951-4e1a-4f9f-b228-0b415d04b72b',
  '3ea0038b-abae-4d5d-b3ce-f3e818ad5f4b',
  '3eff14e5-4b82-4138-8e2a-fede288e815c',
  '3fa100d9-f5f9-4553-be8d-208ec019459c',
  '40f8864b-ce2d-471e-b77c-77aee0defdf7',
  '4198bf1e-24cf-4c41-92ac-650dfae2fa1c',
  '421731c2-0df4-4c84-8e65-fa18cfe42081',
  '4217926f-5941-4e63-9774-084a4495ef94',
  '42668ce8-2ab2-44c3-a38f-390f4c68bd8e',
  '42d25b68-6cc4-4960-a7ce-110558ca2b66',
  '43353afd-d4d1-4ff1-972e-1e974de3857c',
  '44f1947e-c525-4429-9f88-3d588c78b9ec',
  '45caa95b-c6a3-42cf-b745-f173f8b56863',
  '470c60ce-c6c2-4bd4-8dc8-f3b193408442',
  '4749603d-f222-4e00-ab31-2a84ebaa1e85',
  '48da9081-bca3-41ac-bbb9-79fc81950d9b',
  '48eb13b3-0173-44cf-93fd-2a50d20bc792',
  '49d05154-4f33-42ca-9ea7-b3f30a2a78f0',
  '4a74e24f-27ec-4c74-b64f-8710dd87f00f',
  '4aaf2206-b186-4b25-9c0e-4d1b9230028e',
  '4accf762-ebbb-40c8-b973-6fec69ea9187',
  '4b1304bd-67df-4b84-82b6-6822dcecf209',
  '4b8b3217-ba3f-46d1-985e-4047f65990a4',
  '4bb07c76-20da-49ac-a8ba-07fc808e3517',
  '4c18343e-5202-4997-8304-d48dab624853',
  '4c834ff5-e828-4908-a1c9-1b046710fce3',
  '4cdfaaa1-397b-4554-a608-3d5ecde8bbf1',
  '4d145687-d5f3-4304-9a25-f914ba19dd68',
  '4d309941-fbfa-486d-aa2a-29af8c8c2015',
  '4d370a3a-308f-435c-9110-1f346a1b652e',
  '4fcf4e9c-b059-4de3-a57b-ecfb33442f15',
  '4fd410c0-3dcd-4bc0-9b64-b6d83623489d',
  '4fd8082e-49d7-4479-99b6-1a580bacc86d',
  '4fe19e4d-7187-4b15-b352-3e37a6cad34b',
  '4fe81a14-f249-4c02-a100-ff6a5007c0a8',
  '4ffb2777-ad82-4db0-a846-5e50527d8f74',
  '51692605-14a9-4fa1-98f8-be8917b9c29c',
  '51a700ac-32ab-4378-8c04-641def0acc88',
  '51ec2d95-ce97-4c2f-885f-66d290beaa3b',
  '535482b8-651c-450f-84e0-68e64f55bf59',
  '555919ae-021c-422d-87c3-1130e79924d7',
  '56a89bb9-9b4d-41c4-ba88-47af93fdd0f7',
  '572af99f-124b-41f7-af1b-db2ed4066f8d',
  '5820849d-a650-427b-8ac7-9e4b02fa454a',
  '5879b87f-62d7-4efd-9d52-6879fb4b9f7b',
  '5abdb996-7366-452a-b062-8732c9213f5b',
  '5c2455bc-098d-4128-afa5-506948b18acd',
  '5c6b34de-4b26-4155-9dfa-5db298f87cb7',
  '5d710e6a-e29e-421a-8fe0-1c0cd25bc450',
  '5dc4fdd9-c154-4751-92de-93e95bca0b6a',
  '5f61a653-6cd3-4e4d-81ce-69af0b8434ee',
  '5f8ad365-e056-4604-a79e-d4a13392d6f1',
  '5fee9e67-24e8-4ffa-be9f-2b42de37a211',
  '6085fb62-2827-4a92-8898-083d3beabbd0',
  '60dab2ef-dbe5-4d26-96ee-f96d59158c18',
  '6369eb7f-95b4-401c-8a43-fbdf0e153b86',
  '639d10e5-acec-45bd-a89a-d3fa626a5ec2',
  '6423c9fa-2d0c-43b6-a43e-48644bfaf589',
  '64f4e8d0-ec10-425d-8df9-e86c2ce5f759',
  '667ca6ce-1392-4c59-a614-a62f6be9ec70',
  '66baa4d7-a187-4b2e-81a5-96302e391f02',
  '66ded72a-8d1f-4cb8-b22a-8ffcdb9da8bb',
  '67b31a26-79be-4307-8f75-87faea161086',
  '698da035-1133-4680-ad58-3074fc930f09',
  '69defee0-6c31-4566-989c-85828871072b',
  '6a7bb229-e67d-4a26-abbb-7b08d448e556',
  '6aae5a69-c5f4-49e1-a6a3-7b727412cfd4',
  '6ba83eaf-ab21-43fd-93bc-76c9b47fd975',
  '6d48d7b3-0c5d-48be-bd82-9b499c304dd1',
  '6d64d8e5-7089-41f7-a37e-dcdeee9610a2',
  '6e00775c-e74b-4ee3-b5eb-27321e89b3b0',
  '6e0480ba-6bfd-4c91-9d88-0b1260cc3e56',
  '6fc9493c-fbc0-4d86-8777-7e60c18922dc',
  '70f9996f-db2a-4334-b32f-821b976dfcf2',
  '713ddff8-b9df-49fc-bef5-728845c202a6',
  '71e190fa-4439-406c-b4ca-e19c79b3c0b5',
  '732000c7-b2ab-4186-ba38-e21bd3a8d96d',
  '736ae480-d220-4c66-9be5-ab761b654cd3',
  '75049642-cbb0-4183-8d83-faad1fbf8efb',
  '75251b5f-3143-41e3-bf8a-07e430b312f4',
  '76a7374a-397f-43ea-98b9-ded80352e931',
  '76bd5ddd-1711-40f2-bb22-228f33861d37',
  '77ba2ec3-c59f-4f90-8a53-90417110e92d',
  '7859e4de-5a17-4693-bc90-e15f69d017d8',
  '79cb4b19-8410-4730-a92b-67a73cee6fae',
  '79d052a2-6e70-417a-bd72-b661451e69b2',
  '79d8a1da-a897-48c3-97e6-bd649f4ef05d',
  '7a500e34-29e7-4c6e-bf6e-a754aa387c3c',
  '7a51843b-1627-4a26-bde4-907d6aa36a25',
  '7aa80f65-9966-4023-9576-af8629edd752',
  '7c2049a4-d5d0-4eaa-b14d-da1e9ff4b6e9',
  '7c46121d-cc87-400d-8f84-1bdabb5d8184',
  '7ca57285-066b-4c8a-be6d-67484ae16dec',
  '7dfbfcaf-3409-4226-bd48-837d943092b1',
  '7e07f236-2326-4749-a825-53b95c460918',
  '7e9d659a-9260-4339-bdd7-3e3ce45e2acf',
  '7eb6bd5e-1b57-4e37-b485-e8bb3112d7a4',
  '802c73bd-d45c-468a-84f4-23f5077e0bb4',
  '80839e13-e8f0-430a-b8b7-f46c30892277',
  '811daaf9-0ce6-4c49-8953-ca4dd033d1d7',
  '81260192-5471-4404-9a71-52bd45cf0a46',
  '81d3a2f2-e52d-4794-891c-b2e1dd195788',
  '82bcac45-4bea-4597-94d5-e1f6590a51b9',
  '83d6aa14-cc5e-41f1-ad29-5af3fcadfde0',
  '84cf5459-cda8-4194-a264-107e4673c6c1',
  '84def573-bd9e-4d11-acc9-5c35c9080664',
  '84fa8d62-d715-4a94-93ab-8519956c8dae',
  '850ab05a-9763-4a33-aa74-ec6811758af8',
  '85147de1-4551-45b9-916b-16571819b0b7',
  '8528a655-d0d4-4734-a42e-399e03024e2b',
  '855f63d6-cba7-4eaf-878a-cb22ccebdd28',
  '8682fc85-1233-4bde-a94e-282f864c85a3',
  '86c9be0a-dbb5-42d1-83c2-8847189748dc',
  '88e04700-221a-4579-a542-421e8cfc9c8e',
  '89a166bf-a028-4fb2-8aad-83f015852ce5',
  '89f208c6-21bd-4117-a456-7d7e94d09dc4',
  '8a41d188-0aed-4ee2-9fa4-f6fbd32d8640',
  '8aa002b4-2f39-4e45-9ea8-353bd2810be9',
  '8b12dd67-6736-4e55-bcff-ffcff3869ecb',
  '8d27fd5d-72be-484b-b1c4-c2a45463007b',
  '8d43174a-3ee9-4e11-b94b-627382530171',
  '8e8cd71c-bb35-4ca6-8429-ab0511bb9b36',
  '8e9d7d52-c7d0-4632-aeba-3e083072e841',
  '8f0a9d0a-6044-4d70-bdf2-b0db74a8f3da',
  '8f4f7d78-bfa0-4899-8353-8d4f9a23df68',
  '90980deb-120f-41fd-b0f5-bc2f386c85ab',
  '90ee1716-ec9f-4bd3-a70a-19713daa47d7',
  '9118921f-e912-4cb7-9439-2c6a14ade322',
  '921906a5-3e49-4746-a6ac-844b1066487a',
  '925531a0-5116-4d2e-b6c0-9e42d7bd4e72',
  '931ae78f-59dc-406d-8adc-7eaeb0310fc2',
  '934d3062-5cca-41c2-8736-9934becf4d21',
  '93a58432-80c6-4aaa-9804-88f4d57f08bb',
  '93aa1baf-945e-4b0b-93fa-16328be4f073',
  '93ba4641-eff5-48b2-b28d-b9e1d025ee2b',
  '93cab098-4f13-4a78-a92d-27fad86faef1',
  '94396d17-00b7-4c99-8b0d-1d2d8d28f684',
  '94c21a11-95a0-4c23-aeba-c4c86994a856',
  '94d2e520-fee6-4916-b693-452b1fa5d835',
  '94f47f61-5273-4fea-a61a-bb177a1f27d9',
  '9530e894-8362-4eb8-bdfb-8cd7a0ac3204',
  '96b05235-980b-411e-a4c5-937676ab957b',
  '97cdf6bf-8d5e-4691-8742-f59580a0e01b',
  '98733457-b096-4ecf-ac37-9b1103d094ec',
  '98742b25-5160-47be-9d1d-0c32852a586f',
  '9952705c-9e1c-4c9a-938d-1e15d79c9645',
  '99b93126-dd3f-4977-b7fe-c6f39b765c9e',
  '9a2f8f53-c664-4ace-bebf-8e9ec7153cb3',
  '9ab8b42f-d73d-4cd8-8d8a-a8fd5e07c75b',
  '9e88f0dc-d1af-4648-8f12-ca0a47cd8654',
  '9ff0c888-0eb3-42f2-8efa-ddd239e9d036',
  'a0291f05-58da-4bb5-8e29-c80b30ebf99e',
  'a09551e9-6bee-48cb-b3b3-54c853dc1a3e',
  'a3244281-87fd-4531-9d06-f8b771ea1487',
  'a5080417-b1a7-4a65-9148-fa6b16d073bf',
  'a5113f3a-9156-48ca-9204-e78e82246b61',
  'a5b24d46-7a33-4429-8189-fd778e0fe225',
  'a5b392a3-d0da-472a-a87d-f1a4cc24e90f',
  'a5f6f1aa-f13b-4239-bf5b-562b613e6b49',
  'a73925f2-848f-42f2-a283-968ca31dddec',
  'a74f3d1b-f5e5-4602-9a8a-b45f3e16cff7',
  'a876d083-9da3-4f3e-b372-16085ad10f54',
  'a8ee5a37-889a-4be9-8357-b2cfc35f5305',
  'ab4feb9b-189f-4805-8d74-9b9c0d7c5a88',
  'acb750f4-74d1-4fd6-8655-cfe8c5d7a925',
  'acfad43a-16a9-42f9-b1ad-be5a6a2e4a2b',
  'ad898000-1a75-40e9-a09a-d46a6959cabb',
  'af3888d5-239b-40af-9c08-0e0b1fae6b63',
  'afe961a8-cec6-4c6e-bb6c-a64b09635ad7',
  'b0a358e7-203d-45d6-ac78-7cdf53a9480f',
  'b0e5c9ff-97d2-4d83-98c0-8dbcbc5a9960',
  'b103d017-ca5a-4b4c-9761-7f93e2d29944',
  'b12a99f5-ebf6-4864-9da5-e0a86bf1960d',
  'b168ba4e-2866-47f4-9f78-19c071874136',
  'b18a828b-d944-44d2-bd97-8781e624806a',
  'b2ebface-3d16-4911-89aa-5e060c89fd0c',
  'b30ec260-98ff-4b61-9acc-efdd31939b6d',
  'b311dd04-b7ab-4f86-9e05-943549084a59',
  'b468d185-d6db-4cbb-a36d-b4d9b9c6e88b',
  'b5625087-7cd1-4b10-9418-ede07b816773',
  'b5eedaf4-67df-46f8-878f-5da6afb05571',
  'b5fc1db3-393d-4e3d-b245-35c8f84ef055',
  'b6b603fc-ae62-48e6-b9da-83a44d565b22',
  'b6fd1250-8655-4884-ac59-8e40023e5d1d',
  'b7dfe4c5-6aa8-4f78-879b-6a5cad6a1858',
  'b84a3fdc-da39-4106-848e-d0270e70349c',
  'b8a5db4e-0213-4d36-bb4d-de86b65fbb17',
  'b91fff82-a0fd-4763-a044-22c917120dce',
  'b955d94a-6a54-4b78-bcea-2c5595bff99d',
  'b980fb17-bed5-49de-afd0-8328edff467d',
  'b981fe90-02eb-4047-bd62-904b0586f2d7',
  'bad38215-08e3-4ace-b236-dbf112c27b0c',
  'bad87e61-febf-4f49-90a4-343ad8dab6b0',
  'bbacc5af-9aa3-4fa0-9e87-0a8f7da96d7b',
  'bbb9b88f-c34b-4578-8801-e4da13906e03',
  'bc3e0d28-2eb3-443c-b373-b4fdffa4b2cf',
  'bcdf53e8-e0f4-49e8-8914-5d2cf6ecbc45',
  'bce54834-f553-460b-9c74-c1209a81d14f',
  'bce9fe05-e560-46df-9bca-7717395bc30d',
  'bd8cf752-55fb-4391-89d1-dfeb7167d8af',
  'bdd2dd3b-63e9-438b-8dba-8c47f0d81d76',
  'be19e6a3-09f8-42ea-8bd3-68bf061cae7e',
  'be392def-5630-418c-9a4d-6bb77394b559',
  'bebc926c-5ac6-464b-981c-1625681cde0a',
  'c03e6084-c7a0-4f98-b3a3-d092fe0fd984',
  'c05efda6-9f19-40ca-b836-942e87d56a53',
  'c069bfc5-d6ea-41bc-a14a-288aebb7f360',
  'c183a6ee-dd7a-4735-bafe-73247e767bb1',
  'c1a8b0a6-4c3a-46b3-a09c-446c9e9e9dd2',
  'c2542c78-779c-4774-81ce-8b947ee07692',
  'c2a2faf8-cb3d-4f48-807c-ac84655823f7',
  'c332844e-d101-4bc0-88d2-ae18d3673b5b',
  'c3b6304e-1854-4fc8-807a-021a3fbf7402',
  'c4dc5af9-7037-483d-aa3f-cf1898b892f2',
  'c7a492f0-57c2-413b-bf4b-045a26105fdf',
  'c8d9be6d-d884-4d08-8c5b-3e5d6af36725',
  'c9c8bbed-56eb-4227-9deb-7ae0715e786a',
  'c9dab287-953b-4071-bc82-bdc966a82e8e',
  'ccedf4b2-44eb-4c20-8a26-6c2f73d130fe',
  'cd152b6c-d7ed-406d-9456-04e15ba5414a',
  'cd97090d-85e0-41d6-b659-b1df866cd6c7',
  'ce164914-20c7-40d4-a03a-33e1e64e0e1f',
  'ce9a02bf-2193-4359-8343-efed7a9fa16a',
  'cf6cc612-be03-4e68-b458-7c07d6c15670',
  'cf82d901-e4b3-49a6-88d1-81b5d724e566',
  'cf8d49ec-fcf9-4f51-b182-2c213523211a',
  'd18af5ba-816f-43fe-8af4-02e7d4e6905d',
  'd2961f8b-17a2-4e5d-b4d0-348368581133',
  'd2e5b100-513a-4299-a84c-2bf2a31d0b33',
  'd2f080bb-5a54-4e19-81de-c9072d4109e2',
  'd315d6c1-5621-46e7-b0f6-2c06ae871f25',
  'd38c87f7-e72b-4410-a82f-85a7830f93f4',
  'd6baf485-d25b-4103-9098-3a20d57962dc',
  'd6cd763b-0b5a-4491-8950-e61ad8da9a0f',
  'd91508e0-f263-4ed5-be16-b26c4c674c86',
  'd99d28a9-d7a0-41fb-9bda-5f95c89e123f',
  'd9c5c216-78ba-426c-867f-c6732636bd44',
  'd9e30d68-4cb3-45ba-ad1c-da822e7f885b',
  'da8e1870-9b68-46a5-9907-bd6dc4375c7b',
  'dc0348d3-92b4-4070-b806-1417c1efdabc',
  'dd2eb41d-75a3-44cb-8c24-f294a0d3ad54',
  'dd9e7e93-ce61-4f4a-8dae-775be04c181b',
  'de192e68-2295-4752-ad8e-cf18d147056a',
  'de1e5859-8676-4182-a2c1-01724f9d675b',
  'df2f82ab-8234-44f7-841a-2e1c68be4b79',
  'e12c3752-d860-4794-b0e4-4f8df86d3a8f',
  'e1794374-86b5-473a-a966-3a3c12a6798d',
  'e241135e-955f-47a7-bd34-c417bdfd539a',
  'e2a28a06-4d14-4bfd-8949-f134b7e83957',
  'e323e41b-2d76-48ab-90f5-553b4868e7ec',
  'e3c2725e-3df9-42ee-86c7-53ba0323bad9',
  'e459c464-5f4b-4f58-a238-20908ae9934b',
  'e49b0b5c-b5b3-47df-9509-542c8c41fe49',
  'e508188e-ada1-4ae5-8ca5-0709c4d2b6e4',
  'e78fbcfe-d555-4135-a546-408f1a2db6f7',
  'e8bd8c3d-9e42-4528-a9f2-49a92e19ead4',
  'e8c78c69-ea90-4bd5-82be-0c2e8bd0fd0b',
  'e8f9d4cf-ef92-4da4-a263-079e36c13a4b',
  'e9f549ec-67e9-4e48-ab76-807afe8c3287',
  'eba3cab5-ff6c-4468-a0ab-33cd2d07dd25',
  'ecd17a8d-10d5-48ec-9959-9a5358f4a97a',
  'ed3871d3-f99b-4db0-a2d5-2469e2281e57',
  'eda66e5d-7e9a-45ea-ae61-061f2582b9fb',
  'ef758c3f-95a1-40a7-90b9-ae97868dfd35',
  'efa06d56-f1e4-4f95-b4a9-19fbdf80c802',
  'f09a2e93-e9d3-4fc6-80e3-9f0774cc3e99',
  'f10c5f86-7d82-47dd-99b4-9ea9e49728da',
  'f2819c0f-6cbd-4ba1-bd27-39675d5eb33d',
  'f294546c-79bc-449e-b22b-f3d2c8cbb4a2',
  'f2969e4f-dccb-4b81-9ba1-013b837e40ac',
  'f33d7be9-c80b-435f-8bdc-64c2f4166ff9',
  'f39fd922-3016-45ba-99db-85fb1db17614',
  'f537eced-9990-4e8c-9438-03bf18da744a',
  'f6c83c05-3251-4a0c-ab3d-5fd25e76520a',
  'f7179ee1-6507-4efb-8599-5e059cf6cbb9',
  'f7ea896d-a8cc-496e-a203-c21207808b93',
  'f9647159-e57a-4190-a38a-d430135f66a1',
  'f9af77c0-d843-4f26-944f-c8b98fc6af57',
  'f9d0821f-a4dc-4c6d-a90c-9188e9bbf494',
  'fb0b6b2d-7cff-4d83-8a59-2a5617f5cf00',
  'fbcb8621-c08b-421a-9926-0afda22e28ab',
  'fc2afb01-6aea-45ef-9668-aa52f55135cf',
  'fcdc5d11-44ec-47e0-bca3-e2b26a7509f9',
  'fd20a0a5-ab39-46a8-9feb-14c9627eefb5',
  'fe27b860-6a6f-4378-9e87-b32cf72d8a5f',
  'fef9a043-755b-4283-b1c3-357b65925a8c',
  'c537aaba-f3b5-444e-baa1-5c65b10ef2c1',
  '3d5fbcb2-2776-4eaa-9b1a-4fad13234983',
  '63b51dd2-3d45-48ea-bac2-aaf2c18c4800',
  '79fd5bbe-32f6-4003-a928-7db891232ce1',
];
